import React from 'react';

import { Link } from 'gatsby';

import Container from 'gatsby-theme-kalanso-ui/src/components/layout/Container';
import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Footer from 'gatsby-theme-kalanso-ui/src/components/footer/Footer';

const BelowTheFold = () => {
  return (
    <Container>
      <Row justify="Center">
        <Col align="Center">
          <Link to="/go">Order Your Biochar Bucket Here</Link>
        </Col>
      </Row>
      <Row justify="Center">
        <Col align="Center">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default BelowTheFold;
