import React from 'react';

import _styles from './preheadline.module.scss';

const Preheadline = ({ children }) => {
  return (
    <p className={`${_styles.preheadline}`}>
      {children}
    </p>
  );
};

export default Preheadline;
