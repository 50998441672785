import React from 'react';

import _styles from './banner.module.scss';

const Banner = () => {
  return (
    <div className={_styles.banner}>
      Available For Limited Time Only. This Page Will Expire In 00:00:00
    </div>
  );
};

export default Banner;
