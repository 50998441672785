import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/SubHeadline';

import _styles from './subheadline.module.scss';

const Subheadline = ({ children }) => {
  return (
    <Text
      className={`${_styles.subheadline}`}
    >
      {children}
    </Text>
  );
};

export default Subheadline;
