import React from 'react';

import Preheadline from './preheadline';
import Headline from './headline';
import Subheadline from './subheadline';
import Warning from './warning';
import Video from './video';

const Body = (props) => {
  return (
    <>
      <Preheadline>NEW TRAINING (LIMITED REPLAY)</Preheadline>
      <Headline>How To Make Your Soil Better</Headline>
      <Subheadline>
        Click Video To Start 
        <span role="img" aria-label="Point Down">
          {' '}
          👇{' '}
        </span>
      </Subheadline>

      <br />
      <br />

      <Video {...props} />

      <br />
      <br />

      <Warning />

      <br />
      <br />
    </>
  );
};

export default Body;
