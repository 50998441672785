import React from 'react';

import Row from 'gatsby-theme-kalanso-ui/src/components/layout/Row';
import Col from 'gatsby-theme-kalanso-ui/src/components/layout/Col';

import Layout from './layout';
import AboveTheFold from './above-the-fold';
import BelowTheFold from './below-the-fold';
import Body from './body';
import Banner from './banner';

import _utils from 'gatsby-theme-kalanso-ui/src/styles/utils.module.scss';
import _replayStyles from './replay.module.scss';

const ReplayPage = ({ pageContext }) => {
  return (
    <Layout>
      <AboveTheFold>
        <Banner {...pageContext} />

        <br/>

        <Row grow="1">
          <Col
            align="Center"
            justify="Center"
            className={`${_utils.fullWidth} ${_replayStyles.body}`}
          >
            <Body {...pageContext} />
          </Col>
        </Row>
      </AboveTheFold>
      <BelowTheFold {...pageContext} />
    </Layout>
  );
};

export default ReplayPage;
