import React from 'react';

import _videoStyles from 'gatsby-theme-kalanso-ui/src/styles/video.module.scss';

const Video = ({ iframe }) => {
  return (
    <div
      className={`${_videoStyles.iframeWrapper}`}
      dangerouslySetInnerHTML={{ __html: iframe }}
    />
  );
};

export default Video;
