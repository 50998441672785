import React from 'react';

import _replayStyles from './replay.module.scss';

const Warning = () => {
  return (
    <div>
      <p className={`${_replayStyles.instructions}`}>
        Make Sure Your Speakers Are On And Click Play
      </p>
      <p className={`${_replayStyles.warning}`}>
        May Take 10 Seconds To Load
      </p>
    </div>
  );
};

export default Warning;
