import React from 'react';

import Text from 'gatsby-theme-kalanso-ui/src/components/text/Headline';

import _styles from './headline.module.scss';

const Headline = ({ children }) => {
  return (
    <Text className={`${_styles.headline}`}>
      {children}
    </Text>
  );
};

export default Headline;
